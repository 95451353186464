import React from 'react'

export interface FeatureCard {
    color: 'green' | 'yellow' | 'red',
    icon: string,
    title: string,
    text: string,
}

const FeatureCard = ({ color, icon, title, text }: FeatureCard) => {

  const colorClasses = {
    'green': 'bg-mg-green',
    'yellow': 'bg-mg-yellow',
    'red': 'bg-mg-red',
    // Add other colors as needed
  };

  return (
    <div className={`flex flex-col items-start justify-start gap-12 ${colorClasses[color]} rounded-2xl p-8 AnimatedUp`}>
        <div className="h-12 w-12">
            <img src={icon} alt="" width="48px" />
        </div>
        <div className="flex flex-col min-h-14">
            <h3 className="text-xl mb-1">{title}</h3>
            <p className="mb-0 text-base">{text}</p>
        </div>
    </div>
  )
}

export default FeatureCard