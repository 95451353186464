import React from 'react'

export interface TestimonialCard {
    color: string,
    image: string,
    quote: string,
    name: string,
    job: string,
}

const TestimonialCard = ({ color, image, quote, name, job }: TestimonialCard) => {

  return (
    <div className={`flex flex-col items-start md:basis-1/2 gap-6 AnimatedUp p-8 rounded-2xl bg-mg-${color} text-white`}>
        <img src={image} alt="" width="192px" />
        <div className="flex flex-col">
            <p className="mb-0">"{quote}"</p>
        </div>
        <div className="flex flex-col">
            <h3 className="text-lg mb-1">{name}</h3>
            <p className="italic text-sm mb-0">{job}</p>
        </div>
    </div>
  )
}

export default TestimonialCard