import React from 'react'

export interface SectionCard {
    image: string,
    title: string,
    description: string,
}

const SectionCard = ({ image, title, description }: SectionCard) => {

  return (
    <div className="flex flex-col md:basis-1/3 gap-4 AnimatedUp p-4">
        <img src={image} alt="" width="100%" />
        <div className="flex flex-col">
            <h3 className="text-lg text-center mb-1">{title}</h3>
            <p className="text-center">{description}</p>
        </div>
    </div>
  )
}

export default SectionCard