import React from 'react'

export interface Headings {
    subtitle: string,
    title: string
}

const SectionHeading = ({ subtitle, title}: Headings) => {

    return (
        <div className="max-w-xl mx-auto flex flex-col items-center gap-1">
            <span className="text-mg-green uppercase font-semibold">{subtitle}</span>
            <h2 className="text-3xl md:text-5xl text-mg-purple text-center md:leading-tight">{title}</h2>
        </div>
    )
}

export default SectionHeading