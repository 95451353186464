import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import gsap from "gsap";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

gsap.registerPlugin(ScrollToPlugin);

interface ScrollerProps {
  list?: string[];
  count: number;
  data: {
    node: {
      name: string;
      image: IGatsbyImageData;
      link: string;
      id: string;
      logo: IGatsbyImageData;
    };
  }[];
}

interface GridProps {
  logos?: string[];
  logoClass?: string;
  wrapperClass?: string;
  nolinks?: boolean;
}

const LogoScroller = ({ data, count, list }: ScrollerProps) => {
  const images = data.map(({ node }) => {
    const { name, id, logo, link } = node;
    const image = getImage(logo);
    return { id, name, image, link };
  });

  const listImages = list ? images.filter((image) => list.includes(image.name)) : images;

  const animatedTargetRef = useRef<HTMLElement>();
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const target = animatedTargetRef?.current;

    if (!target) {
      return;
    }

    const scrollSpeed = count * (1 - scrollPosition / target.scrollWidth);
    const tl = gsap.timeline({ repeat: -1 });

    let timer: ReturnType<typeof setTimeout>;

    const startRestartDelay = () => {
      timer = setTimeout(() => {
        setScrollPosition(target.scrollLeft);
      }, 100);
    };
    const resetRestartDelay = () => {
      clearTimeout(timer);
    };

    tl.from(target, {
      scrollTo: { y: 0, x: scrollPosition },
    });
    tl.to(target, {
      scrollTo: { y: 0, x: "max" },
      duration: scrollSpeed,
      ease: "none",
      delay: 0.5,
    });
    tl.to(target, {
      scrollTo: { y: 0, x: 0 },
      duration: count,
      ease: "none",
      delay: 0.5,
    });

    target.addEventListener("mouseover", (_e) => {
      tl.kill();
      resetRestartDelay();
    });

    target.addEventListener("mouseleave", (_e) => {
      startRestartDelay();
    });

    target.addEventListener(
      "touchstart",
      (_e) => {
        tl.kill();
        resetRestartDelay();
      },
      { passive: true },
    );

    target.addEventListener(
      "touchend",
      (_e) => {
        startRestartDelay();
      },
      { passive: true },
    );
  }, [scrollPosition]);

  return (
    <div
      ref={animatedTargetRef as any /* override type because of bad typedefs for ref */}
      className="overflow-auto scrollbar-hide whitespace-nowrap"
    >
      {listImages.slice(0, count).map((logo) => (
        <div key={logo.id} className="inline-flex items-center justify-center relative px-6">
          <a
            href={logo.link}
            title={logo.name}
            target="_blank"
            className="opacity-50 hover:opacity-100"
          >
            {logo.image && <GatsbyImage image={logo.image} alt={`${logo.name} logo`} />}
          </a>
        </div>
      ))}
    </div>
  );
};

const LogoGrid = ({ logos, logoClass, wrapperClass, nolinks }: GridProps) => {
  const data = useStaticQuery<{
    allLogosYaml: {
      edges: Array<{
        node: {
          id: string;
          link: string;
          logo: ImageDataLike;
          name: string;
        };
      }>;
    };
  }>(graphql`
    query LogosQuery {
      allLogosYaml(sort: { fields: name }) {
        totalCount
        edges {
          node {
            id
            name
            link
            type
            logo {
              childImageSharp {
                gatsbyImageData(
                  width: 128
                  height: 55
                  formats: [AUTO, WEBP]
                  transformOptions: { fit: CONTAIN }
                  placeholder: NONE
                  backgroundColor: "transparent"
                )
              }
            }
          }
        }
      }
    }
  `);

  const images = data.allLogosYaml.edges.map(({ node }) => {
    const { name, id, logo, link } = node;
    const image = getImage(logo);

    if (!image) {
      throw new Error(`getImage failed for logo ${id}, ${name}`);
    }

    return { id, name, image, link };
  });

  const listImages = logos
    ? images.filter((image: { name: string }) => logos.includes(image.name))
    : images;

  return (
    <div className={`grid grid-cols-3 gap-10 ${wrapperClass}`}>
      {listImages.map((logo) => (
        <div key={logo.name} className="inline-flex items-center justify-center relative">
          {nolinks ? (
            <GatsbyImage
              className={`${logoClass} opacity-30`}
              image={logo.image}
              alt={`${logo.name} logo`}
            />
          ) : (
            <a
              href={logo.link}
              title={logo.name}
              target="_blank"
              className={`${logoClass} opacity-30 hover:opacity-100`}
            >
              <GatsbyImage image={logo.image} alt={`${logo.name} logo`} />
            </a>
          )}
        </div>
      ))}
    </div>
  );
};

export { LogoScroller, LogoGrid };
